import React from 'react';

import { NOT_FOUND } from '@glass/common/modules/http/statusCodes';

import DynamicRockErrorPage from '@glass/shared/components/Error/DynamicRockErrorPage';

function Error404(props) {
  return <DynamicRockErrorPage {...props} statusCode={NOT_FOUND} />;
}

export default Error404;

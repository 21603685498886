import PropTypes from 'prop-types';
import React from 'react';

import SpinnerWithContainer from '@glass/web/components/Loading/SpinnerWithContainer';

function DynamicLoadingSpinnerWithContainer({ isLoading, error, pastDelay, ...otherProps }) {
  if (__DEV__ && error) {
    return (
      <p>
        {error.message}
        <br />
        {error.stack}
      </p>
    );
  }
  if (isLoading) {
    return <SpinnerWithContainer loading {...otherProps} />;
  }
  return null;
}

DynamicLoadingSpinnerWithContainer.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    stack: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  pastDelay: PropTypes.bool,
};

export default DynamicLoadingSpinnerWithContainer;

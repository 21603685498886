import dynamic from 'next/dynamic';

import DynamicLoadingSpinnerWithContainer from '@glass/shared/components/Loading/DynamicLoadingSpinnerWithContainer';

const DynamicRockErrorPage = dynamic(() => import('@glass/shared/components/Error/RockErrorPage'), {
  loading: DynamicLoadingSpinnerWithContainer,
  ssr: true,
});

export default DynamicRockErrorPage;
